import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import {Section, SectionDescription, SectionImageBkg, SectionPageTitle, SectionSubTitle, ExploreMores } from "../components/Section"
import StructuresTypes from "../components/StructuresTypes"
import StructureCarousel from "../components/StructureCarousel"
import StarRating from "../components/StarRating"
import DefaultButton from "../components/Button/DefaultButton"


const SectionBanner = styled(Section)`    
  padding:60px 0 0;
  @media(min-width:768px){
    padding:100px 0 0;
  }
  @media(min-width:992px){
    padding:110px 0 0;
  }
  @media(min-width:1200px){
    padding:120px 0 0;
}
  @media(min-width:1440px){   
    padding:140px 0 0;
  }
  @media(min-width:1600px){
    padding:240px 0 0;
  }
  &:before {
    content: attr(data-shadowtext);
    top:0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.07);
    text-transform: uppercase;
    font-weight: 900;
    z-index:1;
    display:block;
    font-size: 60px;
    line-height: 40px;
    @media (min-width:768px) {    
      font-size: 80px;
      line-height: 50px;
    }
    @media (min-width: 992px) {    
      font-size: 100px;
      line-height: 60px;
    }
    @media (min-width: 1200px) {    
      font-size: 130px;
      line-height: 80px;
    }
    @media (min-width: 1440px) {
      font-size: 190px;
      line-height: 110px;
    }
    @media (min-width: 1600px) {
      font-size: 260px;
      line-height: 160px;
    }
    @media (min-width: 1900px) {
      font-size: 290px;
      line-height: 180px;
    }
  }
`
const Flexbox = styled.div`
  position:relative;
  display:flex;
  flex-wrap:wrap;
  align-items: flex-start;
  margin:0 -15px;    
  @media (min-width: 1650px) {
    padding:0 30px;
  }
`
const FlexboxLeft = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;

  @media (min-width: 768px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`
const FlexboxRight = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  display:flex;
  justify-content:center;
  align-self: flex-end;

  @media (min-width: 768px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .gatsby-image-wrapper{
    width:100%;
    margin: 0 auto;
    display: block;
    max-width: 240px;
    @media (min-width: 1200px) {
      max-width: 290px;
    }
    @media (min-width: 1440px) {
      max-width: 390px;
    }
  }
`
const Sections = styled(Section)`
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  padding:40px 0;
  @media (min-width: 992px) {
    padding:60px 0;
  }
  @media (min-width: 1600px) {
    padding:0;
  }

`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content:space-between;
  margin:0 -15px;
  @media (min-width: 992px) {
    margin:0 -30px;
  }
`
const GridLeft = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media (min-width: 992px) {
    padding:0 30px;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`
const GridRight = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media (min-width: 992px) {
    padding:0 30px;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  @media (min-width: 1440px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`
const Review = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin:0 -15px;
`
const ReviewPosted = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -9px;
    flex-direction:column;
    @media(min-width:992px){
        flex-direction:row;
    }
`
const ReviewRate = styled.div`
    padding:0 9px;
`
const PostedDate = styled.div`
    padding:0 9px;
    color:#999;
    font-size:14px;
    line-height:40px;
    font-weight:500;
`
const ViewList = styled.div`
  margin-bottom:30px;
  width: 100%;
`   
const ViewListItem = styled.div`
  + div{
    padding-top:30px;
    margin-top:30px;
    border-top:1px solid #ccc;
  }
  h3 {
    font-size: 32px;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 0;
  }
  p{
    color:#000;
    font-weight:500;
    font-style:italic;
  font-size:20px;
  line-height:36px; 
    @media(min-width:1200px){
      font-size:22px;
      line-height:40px; 
    }     
    @media(min-width:1600px){
      font-size:28px;
      line-height:50px; 
    }     
  }
`


const SectionQuality = styled(Section)`   
  padding:80px 0 40px 0;
  @media(min-width:768px){
    padding:310px 0 240px 0;
  }
  @media(min-width:992px){
    padding:320px 0 250px 0;
  }
  @media(min-width:1200px){
    padding:380px 0 260px 0;
  }
  @media(min-width:1440px){   
    padding:480px 0 350px 0;
  }
  @media(min-width:1600px){
    padding:500px 0 350px 0;
  }
  .container{
    max-width:1010px;
  }
  &:before {
    content: attr(data-shadowtext);
    top:0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    color:#F3F9FC;
    text-transform: uppercase;
    font-weight: 900;
    z-index:1;
    display:block;
    font-size: 60px;
    line-height: 40px;
    @media (min-width:768px) {    
      font-size: 80px;
      line-height: 50px;
    }
    @media (min-width: 992px) {    
      font-size: 100px;
      line-height: 60px;
    }
    @media (min-width: 1200px) {    
      font-size: 130px;
      line-height: 80px;
    }
    @media (min-width: 1440px) {
      font-size: 190px;
      line-height: 110px;
    }
    @media (min-width: 1600px) {
      font-size: 260px;
      line-height: 160px;
    }
    @media (min-width: 1900px) {
      font-size: 290px;
      line-height: 180px;
    }
  }
`
const Thumbnail = styled.div`
  display:inline-flex;
  align-items:center;
  justify-content:center;
  position:relative;
  .gatsby-image-wrapper{
    border-radius:50%;
    position:relative;
    z-index:1;
  }
  &:after, &:before{
    content:'';
    position:absolute;
    border-radius:50%;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%) ;
  }
  &:before{
    background-color:rgba(19,131,193,0.05);
    width:120%;
    height:120%;
    @media(min-width:1200px){
      width:140%;
      height:140%;
    }
    @media(min-width:1600px){
      width:160%;
      height:160%;
    }
  }
  &:after{
      background-color:rgba(19,131,193,0.05);
    width:110%;
    height:110%;
    @media(min-width:1200px){
      width:120%;
      height:120%;
    }
    @media(min-width:1600px){
      width:130%;
      height:130%;
    }
  }  
    
`
const ThumbnailCircle = styled.div`
  display: flex;
  flex-wrap:wrap;
  margin:0 -15px;
`
const ThumbnailTitle = styled.div`
  position: relative;
  z-index: 1;
  background: #1383C1;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 0 20px rgb(19 131 193 / 30%);
  bottom: 0;
  margin: 0 auto;
  padding: 10px 15px;
  @media(min-width:768px){
    padding: 10px 20px;
    bottom: 100px;
  }
  > p{
    margin-bottom:0;
    font-weight:600;
    letter-spacing: 0.5px;
    font-size: 16px;
    line-height: 28px;
    @media(min-width:768px){
      font-size: 18px;
      line-height: 30px;
    }
  }
`
const ThumbnailInner = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
  margin: 0 0 40px;
  @media(min-width:768px){ 
    width:530px;
    height:530px;
    margin: 0 auto;
    border-radius:50%;
    background-color:#E8F3F9;
  }
  > p{
    width:100%;
    margin:0 auto;
    color:#000;
    font-weight:700;
    font-size:20px;
    line-height:30px;
    @media(min-width:768px){ 
      max-width: 278px;
    }
    @media(min-width:1200px){
      font-size:22px;
      line-height:40px;
    }
    @media(min-width:1600px){
      font-size:30px;
      line-height:40px; 
    }
  }
`

const ThumbnailItemCircle = styled.div`
  display:inline-block;
  text-align: center;
  margin-bottom:40px;
  position:relative;
  padding:0 15px;
  width:100%;
  @media(min-width:576px){
    flex: 0 0 50%;
    max-width: 50%;
    width: inherit;
  }
  @media(min-width:768px){
    flex: 0 0 100%;
    max-width: 100%;
    position:absolute;
    margin-bottom:0;
    &:nth-child(1){
      bottom:calc(100% - 100px);
      left:50%;
      transform:translateX(-50%);
    }
    &:nth-child(2){
      top: -30px;
      right: 35px;
    }
    &:nth-child(3){
      top: 62%;
      right: 40px;
    }


    &:nth-child(4){
      top:calc(100% - 60px);
      left:50%;
      transform:translateX(-50%);
    }

    &:nth-child(5){
      top: 62%;
      left: 50px;
    }
    &:nth-child(6){
      top: -30px;
      left: 35px;
    }
  }
`
const AboutUsPage = ({ location, data }) => {
  const reviews = data.allContentfulReview.edges;
  return (
    <Layout location={location}>
      <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
      />
      <SectionBanner pt="0" pb="0" bgColor="#fff" className="section-about" data-shadowtext="Welcome to"> 
        <SectionImageBkg opacity="0.1"><StaticImage src="../images/inside-center-carport.jpg" alt="inside-center-carport" /></SectionImageBkg>
        <div className="container">
          <Flexbox>
            <FlexboxLeft>
                <SectionPageTitle>Tubular Building Systems</SectionPageTitle>
                <SectionDescription maxWidth="1090px" ml="0">
                  <p>Tubular Building Systems is one of the leading manufacturers of premium quality steel buildings in Florida and part of Southern Georgia. We have been delivering Florida-certified buildings for over a decade and will continue to do so. All of our products are manufactured right here on American land. We are always improving and innovating new structures in the metal building industry. With years of experience in manufacturing steel buildings, we strive to provide our dealers with the best products, best price, and best services possible. Our goal is to beat the harsh weather conditions of Florida with the best certified buildings. That’s really what we’re all about.</p>
                </SectionDescription>
            </FlexboxLeft>
            <FlexboxRight>
              <StaticImage src="../images/mascot-watermark.png" alt="mascot-watermark" />
            </FlexboxRight>
          </Flexbox>
        </div>
      </SectionBanner>

      <SectionQuality pt="0" pb="0" bgColor="#fff" className="section-quality" data-shadowtext="Why TBS?" afterColor="#F3F9FC">
        <div className="container">
          <ThumbnailInner><p>Here are a few things that set Tubular Building Systems apart and make us a top choice for our Dealers & Customers</p></ThumbnailInner>
          <ThumbnailCircle>
            <ThumbnailItemCircle>
              <Thumbnail><StaticImage src="../images/customer-service.jpg" alt="customer-service" /></Thumbnail>
              <ThumbnailTitle><p>Outstanding Customer Service</p></ThumbnailTitle>
            </ThumbnailItemCircle>
            
            <ThumbnailItemCircle>
              <Thumbnail><StaticImage src="../images/delivery-installation.jpg" alt="delivery-installation" /></Thumbnail>
                <ThumbnailTitle><p>Free Delivery & Installation</p></ThumbnailTitle>
            </ThumbnailItemCircle>
            
            <ThumbnailItemCircle>
              <Thumbnail><StaticImage src="../images/transparency.jpg" alt="transparency" /></Thumbnail>
              <ThumbnailTitle><p>Complete Transparency</p></ThumbnailTitle>
            </ThumbnailItemCircle>
            
            <ThumbnailItemCircle>
              <Thumbnail><StaticImage src="../images/american-made.jpg" alt="american-made" /></Thumbnail>
              <ThumbnailTitle><p>100% American-made Buildings</p></ThumbnailTitle>
            </ThumbnailItemCircle>
            
            <ThumbnailItemCircle>
              <Thumbnail><StaticImage src="../images/guarantee.jpg" alt="guarantee" /></Thumbnail>
              <ThumbnailTitle><p>Best Price Guarantee</p></ThumbnailTitle>
            </ThumbnailItemCircle>
            
            <ThumbnailItemCircle>
              <Thumbnail><StaticImage src="../images/high-quality.jpg" alt="high-quality" /></Thumbnail>
              <ThumbnailTitle><p>High-Quality Steel Buildings</p></ThumbnailTitle>
            </ThumbnailItemCircle>            
          </ThumbnailCircle>
        </div>
      </SectionQuality>

      <Section pt="120px" pb="120px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8" className="section-review">
        <div className="container">        
          <Review>
            <SectionSubTitle mb="15px">Customer Reviews</SectionSubTitle>
            <ViewList>
            {
              reviews.map((item, i) => (
                <ViewListItem key={i}>
                  <h3>{item.node.reviewer}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.node.reviewText.childMarkdownRemark.html,
                    }}
                  />
                  <ReviewPosted className="review-post">
                      <ReviewRate>
                          <StarRating name="rating1" ragingId1={`${i}-1`} ragingId2={`${i}-2`} ragingId3={`${i}-3`} ragingId4={`${i}-4`} ragingId5={`${i}-5`} reviewStar={item.node.star} />
                      </ReviewRate>
                      <PostedDate className="post-date">{item.node.createdAt}</PostedDate>
                  </ReviewPosted>
                </ViewListItem>
              ))
            }
            </ViewList>
            <ExploreMores mr="auto" ml="auto">
              <Link to="/reviews"><DefaultButton primary text="More Reviews" /></Link>
            </ExploreMores>
          </Review>
        </div>
      </Section>

      <Sections pt="0" pb="0" bgColor="#fff" className="section-structure"> 
        <div className="container">
          <Grid>
            <GridLeft>
              <SectionSubTitle mb="20px">GET IDEAS FROM THESE STRUCTURES</SectionSubTitle>
              <StructureCarousel data={data.allContentfulProductCategory.edges} />
            </GridLeft>
            <GridRight><StructuresTypes /></GridRight>
          </Grid>
        </div>
      </Sections>
    </Layout>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageQuery{
    allContentfulProductCategory {
      edges {
        node {
          name
          cardImage {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
    allContentfulPageData(filter: {page: {eq: "About Us"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    allContentfulReview(sort: {fields: createdAt, order: DESC}, limit: 2) {
      edges {
        node {
          reviewer
          star
          createdAt(formatString: "DD MMMM YYYY")
          reviewText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
